#application-bar{

    .session-data{
        font-size:.8rem;
    }

    .logo-img{
        height : 42px;
    }

    .route-trigger{
        width:256px;
        text-align: left;
        text-transform: none;
    }

    .route-trigger img{
        float: right;
        margin-top:8px;
        height:20px;
    }


    @media (max-width: $legacy-max-width){
        .route-trigger{
            width:180px;
        }
    }

}