.dashboard{

    height:100%;

    md-card{
        min-width:260px;
    }

    .kpi{

        margin:0;
        padding:0;
        font-size:1.4rem;
        line-height: 2rem;
        text-transform: none;
        color: paper-color("fundy-blue", "500");
        
        .kpi-value{
            color: #333333;
        }

    }

    @media (max-width: $legacy-max-width){
        md-card{
            min-width:200px;

            .md-headline{
                font-size:16px;
            }
        }
    }
    
}

 

