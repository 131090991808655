#error-template{
    text-align: center;
    color : color("fundy-orange", "400");
    
    @media (min-width: $mobile-min-width){
        img{
            width:460px;
        }
    }

    @media (min-width: $tablet-min-width){
        img{
            width:860px;
        }
    }

    @media (max-width: $legacy-max-width){
        img{
            width:300px;
        }
    }

   


}