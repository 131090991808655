.date-range-picker{

label{
    height:1.4rem;
}

 input{
    height:1.4rem;
    width:160px;
}

button{
    line-height: 1.6rem;
    height:1.6rem;
    min-height:0;
    min-width:0;
    font-size: .8rem;
    text-transform: none;
    padding:0px 10px;
    margin: 0;
    font-weight:400;
    vertical-align: top;    
}

}

@media (max-width: $legacy-max-width){
    .date-range-picker{
        width:100%; 

        input{
            width:100%;
            height:1.4rem;
            margin:2px 0;
        }

        .date-expander-icon{
            width:24px;
            height: auto;
            margin: 0 10px;
        }

        .date-expanded-icon{
            widows: 64px;
            height:64px;
            margin: 0px 0px 0px 1rem;
        }
    }


}

