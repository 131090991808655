// Define desired color palettes.
$primary: "fundy-blue";
$accent: "fundy-orange";
$warn: "pink";

@import "color-palette";

$color-fundy-blue: (
  '50': #e5f0f5,
  '100': #cbe2ea,
  '200': #b1d3e0,
  '300': #97c4d6,
  '400': #7db6cb,
  '500': #63A7C1,
  '600': #4f869a,
  '700': #3b6474,
  '800': #28434d,
  '900': #142127,
  'A100': #82b1ff,
  'A200': #448aff,
  'A400': #2979ff,
  'A700': #2962ff,
  'contrastDefaultColor': $light-contrast-color,
  'contrastDarkColors': ('50', '100', '200', '300', '400', 'A100'),
  'contrastStrongLightColors': ('500', '600', '700', 'A200', 'A400', 'A700')
) !default;

$color-fundy-orange: (
  '50': #fae4da,
  '100': #f5cab5,
  '200': #f0b090,
  '300': #eb956a,
  '400': #e67b45,
  '500': #e16020,
  '600': #b44d1a,
  '700': #873a13,
  '800': #5a260d,
  '900': #2d1306,
  'A100': #ffd180,
  'A200': #ffab40,
  'A400': #ff9100,
  'A700': #ff6d00,
  'contrastDefaultColor': $light-contrast-color,
  'contrastDarkColors': ('50', '100', '200', '300', '400', 'A100'),
  'contrastStrongLightColors': ('500', '600', '700', 'A200', 'A400', 'A700')
) !default;

$colors: map-merge($colors,(
  'fundy-orange': $color-fundy-orange,
  'fundy-blue': $color-fundy-blue,
));



