@import 'users-report';
@import 'revenue-report';
@import 'pe-report';

.report-container{
    margin:20px;
}

.md-cell.md-numeric{
    text-align: right;
}

.report-key{
    font-weight: bold;
}
