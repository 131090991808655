@import 'brand-palette';
@import 'breakpoints';
@import 'error';
@import 'ember-paper';
@import "ember-paper-expansion-panel";
@import 'spinner';
@import 'dashboard';
@import 'reports';
@import 'table';
@import 'application-bar';
@import 'components/date-range';


.toast-warn{
    background-color: $warn;
}

.toast-accent{
    background-color: $accent;
}

.login-form{
    height:100%;

    label{
        vertical-align: middle;
        height: 54px;
        margin: 18px 10px;
        padding:2px;
    }
}

.link-button{
    text-decoration: underline;
    text-transform: none;
    font-weight: normal;
    font-size: .8rem;
    line-height: 1rem;
    padding:0;
    margin:0;
    min-width: 0;
    min-height: 0;

}

 
 

 
