$min-pe-table-width:360px;

.pe-report{
            .md-table{
            min-width: $min-pe-table-width;
            tr:nth-child(4){
                td:nth-child(2){
                    border-top:2px solid black;
                }
            }
            .md-row{
                .md-cell{width:80px;}
                .md-cell.report-key{
                    width:182px;
                }
            }
      
        }
    }

.report-title {
    text-align: center;
}

.refreshed {
    margin-top: 0.5rem;
    margin-left: 2rem;
}


